.voice-chat {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.voice-chat .error {
    color: red;
}

.voice-chat .mute-button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
}

.voice-chat .audio-indicator {
    margin: 10px 0;
}

.voice-chat .connection-status {
    font-weight: bold;
}

.voice-chat .connection-status.connected {
    color: green;
}

.voice-chat .connection-status.disconnected {
    color: red;
}